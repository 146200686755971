body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }
  a {
    @apply text-blue-600 underline;
  }
  ul,
  ol {
    list-style: revert;
  }
  table {
    border-collapse: collapse;
  }
  th,
  td {
    padding: 5px;
    border: 1px solid rgb(32, 32, 30);
  }
} */

/* tailwind.config.css */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}